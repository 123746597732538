:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._pt-t-space-spa1360334047{padding-top:var(--t-space-spacing24);}
:root ._pr-t-space-spa1360334047{padding-right:var(--t-space-spacing24);}
:root ._pb-t-space-spa1360334047{padding-bottom:var(--t-space-spacing24);}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._borderTopWidth-t-space-spa94665586{border-top-width:var(--t-space-spacing1);}
:root ._borderRightWidth-t-space-spa94665586{border-right-width:var(--t-space-spacing1);}
:root ._borderBottomWidth-t-space-spa94665586{border-bottom-width:var(--t-space-spacing1);}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._borderTopLeftRadius-t-radius-ro291586424{border-top-left-radius:var(--t-radius-rounded20);}
:root ._borderTopRightRadius-t-radius-ro291586424{border-top-right-radius:var(--t-radius-rounded20);}
:root ._borderBottomRightRadius-t-radius-ro291586424{border-bottom-right-radius:var(--t-radius-rounded20);}
:root ._borderBottomLeftRadius-t-radius-ro291586424{border-bottom-left-radius:var(--t-radius-rounded20);}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._width-10037{width:100%;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap1598{gap:var(--t-space-gap20);}}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_flex-start{align-items:flex-start;}}
:root ._display-none{display:none;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_block{display:block;}}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}
:root ._gap-10px{gap:10px;}