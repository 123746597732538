:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._height-1px{height:1px;}
:root ._backgroundColor-surface3{background-color:surface3;}
:root ._mt-t-space-spa94665589{margin-top:var(--t-space-spacing4);}
:root ._mb-t-space-spa94665589{margin-bottom:var(--t-space-spacing4);}
:root ._borderTopWidth-0px{border-top-width:0px;}
:root ._borderRightWidth-0px{border-right-width:0px;}
:root ._borderBottomWidth-0px{border-bottom-width:0px;}
:root ._borderLeftWidth-0px{border-left-width:0px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}