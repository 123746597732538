:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._position-absolute{position:absolute;}
:root ._left-28px{left:28px;}
:root ._top-14px{top:14px;}
:root ._position-relative{position:relative;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}