:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._alignItems-center{align-items:center;}
:root ._gap-t-space-spa94665589{gap:var(--t-space-spacing4);}
:root ._width-10037{width:100%;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexGrow-1{flex-grow:1;}
:root ._justifyContent-flex-end{justify-content:flex-end;}
:root ._gap-10px{gap:10px;}